import axios from 'axios';
import router from "./router/Router";
axios.defaults.baseURL = process.env.VUE_APP_APIURL;
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {


    if (response.data.force_logout === 1 && response.data.status === false) {
      if (localStorage.getItem('authenticated')) {
        localStorage.clear();
        router.push("/login");

      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
